// client/src/pages/admin/AdminUsers.js
import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import './Admin.css';

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    is_admin: false
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await api.get('/api/admin/users');
      setUsers(response.data);
    } catch (error) {
      setError('Ошибка загрузки пользователей');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    
    try {
      const response = await api.post('/api/admin/users', formData);
      setUsers([...users, response.data]);
      setFormData({
        username: '',
        email: '',
        password: '',
        is_admin: false
      });
      setShowCreateForm(false);
      toast.success('Пользователь успешно создан!');
    } catch (error) {
      toast.error(error.response?.data?.error || 'Ошибка создания пользователя');
      console.error(error);
    }
  };

  const handleEditUser = async (e) => {
    e.preventDefault();
    
    try {
      const response = await api.put(`/api/admin/users/${selectedUser.id}`, formData);
      setUsers(users.map(user => user.id === selectedUser.id ? response.data : user));
      setShowEditForm(false);
      setSelectedUser(null);
      toast.success('Пользователь успешно обновлен!');
    } catch (error) {
      toast.error(error.response?.data?.error || 'Ошибка обновления пользователя');
      console.error(error);
    }
  };

  const handleDeleteUser = async (id) => {
    if (!window.confirm('Вы уверены, что хотите удалить этого пользователя?')) {
      return;
    }
    
    try {
      await api.delete(`/api/admin/users/${id}`);
      setUsers(users.filter(user => user.id !== id));
      toast.success('Пользователь успешно удален!');
    } catch (error) {
      toast.error(error.response?.data?.error || 'Ошибка удаления пользователя');
      console.error(error);
    }
  };

  const openEditForm = (user) => {
    setSelectedUser(user);
    setFormData({
      username: user.username,
      email: user.email,
      password: '',
      is_admin: user.is_admin
    });
    setShowEditForm(true);
  };

  if (loading) {
    return (
      <div className="loading">
        <div className="spinner"></div>
        <p>Загрузка пользователей...</p>
      </div>
    );
  }

  return (
    <div className="admin-users">
      <div className="page-header">
        <h1>Управление пользователями</h1>
        <button 
          className="btn btn-primary" 
          onClick={() => {
            setShowCreateForm(!showCreateForm);
            setShowEditForm(false);
            setFormData({
              username: '',
              email: '',
              password: '',
              is_admin: false
            });
          }}
        >
          {showCreateForm ? 'Отмена' : 'Создать пользователя'}
        </button>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}

      {showCreateForm && (
        <div className="card mb-4">
          <div className="card-header">
            <h2>Создать нового пользователя</h2>
          </div>
          <div className="card-body">
            <form onSubmit={handleCreateUser}>
              <div className="form-group">
                <label htmlFor="username">Имя пользователя</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  className="form-control"
                  value={formData.username}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Пароль</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="is_admin"
                    name="is_admin"
                    className="form-check-input"
                    checked={formData.is_admin}
                    onChange={handleInputChange}
                  />
                  <label className="form-check-label" htmlFor="is_admin">
                    Администратор
                  </label>
                </div>
              </div>
              <button type="submit" className="btn btn-success">
                Создать пользователя
              </button>
            </form>
          </div>
        </div>
      )}

      {showEditForm && selectedUser && (
        <div className="card mb-4">
          <div className="card-header">
            <h2>Редактировать пользователя</h2>
          </div>
          <div className="card-body">
            <form onSubmit={handleEditUser}>
              <div className="form-group">
                <label htmlFor="edit_username">Имя пользователя</label>
                <input
                  type="text"
                  id="edit_username"
                  name="username"
                  className="form-control"
                  value={formData.username}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="edit_email">Email</label>
                <input
                  type="email"
                  id="edit_email"
                  name="email"
                  className="form-control"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="edit_password">Новый пароль (оставьте пустым, чтобы не менять)</label>
                <input
                  type="password"
                  id="edit_password"
                  name="password"
                  className="form-control"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="edit_is_admin"
                    name="is_admin"
                    className="form-check-input"
                    checked={formData.is_admin}
                    onChange={handleInputChange}
                  />
                  <label className="form-check-label" htmlFor="edit_is_admin">
                    Администратор
                  </label>
                </div>
              </div>
              <div className="form-actions">
                <button type="submit" className="btn btn-success">
                  Сохранить изменения
                </button>
                <button 
                  type="button" 
                  className="btn btn-secondary"
                  onClick={() => {
                    setShowEditForm(false);
                    setSelectedUser(null);
                  }}
                >
                  Отмена
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="card">
        <div className="card-header">
          <h2>Список пользователей</h2>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Имя пользователя</th>
                  <th>Email</th>
                  <th>Роль</th>
                  <th>Проекты</th>
                  <th>Дата регистрации</th>
                  <th>Действия</th>
                </tr>
              </thead>
              <tbody>
                {users.map(user => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>
                      <span className={`badge ${user.is_admin ? 'badge-primary' : 'badge-secondary'}`}>
                        {user.is_admin ? 'Администратор' : 'Пользователь'}
                      </span>
                    </td>
                    <td>{user.projects_count}</td>
                    <td>{new Date(user.created_at).toLocaleDateString('ru-RU')}</td>
                    <td>
                      <div className="btn-group">
                        <button
                          className="btn btn-sm btn-secondary"
                          onClick={() => openEditForm(user)}
                        >
                          Изменить
                        </button>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDeleteUser(user.id)}
                        >
                          Удалить
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;