// client/src/pages/projects/ProjectDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart, ArcElement, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import './ProjectDetail.css';

// Регистрация необходимых компонентов Chart.js
Chart.register(ArcElement, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const ProjectDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [links, setLinks] = useState([]);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editedProject, setEditedProject] = useState({});

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        // Получение информации о проекте
        const projectResponse = await api.get(`/api/projects/${id}`);
        setProject(projectResponse.data);
        setEditedProject(projectResponse.data);
        
        // Получение ссылок проекта
        const linksResponse = await api.get(`/api/links/project/${id}`);
        setLinks(linksResponse.data);
        
        // Получение статистики проекта
        const statsResponse = await api.get(`/api/clicks/stats/project/${id}`);
        setStats(statsResponse.data);
      } catch (error) {
        setError('Ошибка загрузки данных проекта');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [id]);

  const handleUpdateProject = async (e) => {
    e.preventDefault();
    
    try {
      const response = await api.put(`/api/projects/${id}`, editedProject);
      setProject(response.data);
      setEditMode(false);
      toast.success('Проект успешно обновлен!');
    } catch (error) {
      toast.error('Ошибка обновления проекта');
      console.error(error);
    }
  };

  const handleDeleteProject = async () => {
    if (!window.confirm('Вы уверены, что хотите удалить этот проект?')) {
      return;
    }
    
    try {
      await api.delete(`/api/projects/${id}`);
      toast.success('Проект успешно удален!');
      navigate('/projects');
    } catch (error) {
      toast.error('Ошибка удаления проекта');
      console.error(error);
    }
  };

  const handleDeleteLink = async (linkId) => {
    if (!window.confirm('Вы уверены, что хотите удалить эту ссылку?')) {
      return;
    }
    
    try {
      await api.delete(`/api/links/${linkId}`);
      setLinks(links.filter(link => link.id !== linkId));
      toast.success('Ссылка успешно удалена!');
    } catch (error) {
      toast.error('Ошибка удаления ссылки');
      console.error(error);
    }
  };

  if (loading) {
    return (
      <div className="loading">
        <div className="spinner"></div>
        <p>Загрузка данных проекта...</p>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  // Подготовка данных для графиков
  const deviceChartData = {
    labels: stats?.deviceStats.map(item => item.device_type || 'Неизвестно'),
    datasets: [
      {
        data: stats?.deviceStats.map(item => item.click_count),
        backgroundColor: [
          'rgba(74, 108, 247, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const linkChartData = {
    labels: stats?.linkStats.slice(0, 10).map(item => item.name),
    datasets: [
      {
        label: 'Клики',
        data: stats?.linkStats.slice(0, 10).map(item => item.click_count),
        backgroundColor: 'rgba(74, 108, 247, 0.8)',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  return (
    <div className="project-detail">
      <div className="page-header">
        <h1>{project.name}</h1>
        <div className="header-actions">
          {!editMode && (
            <>
              <button className="btn btn-primary" onClick={() => setEditMode(true)}>
                Редактировать
              </button>
              <button className="btn btn-danger" onClick={handleDeleteProject}>
                Удалить
              </button>
            </>
          )}
        </div>
      </div>

      {editMode ? (
        <div className="card mb-3">
          <div className="card-header">
            <h2>Редактирование проекта</h2>
          </div>
          <div className="card-body">
            <form onSubmit={handleUpdateProject}>
              <div className="form-group">
                <label htmlFor="name">Название проекта</label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  value={editedProject.name}
                  onChange={(e) => setEditedProject({ ...editedProject, name: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Описание (необязательно)</label>
                <textarea
                  id="description"
                  className="form-control"
                  rows="3"
                  value={editedProject.description || ''}
                  onChange={(e) => setEditedProject({ ...editedProject, description: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="status">Статус</label>
                <select
                  id="status"
                  className="form-control"
                  value={editedProject.status}
                  onChange={(e) => setEditedProject({ ...editedProject, status: e.target.value })}
                >
                  <option value="active">Активный</option>
                  <option value="paused">Приостановлен</option>
                  <option value="archived">Архивирован</option>
                </select>
              </div>
              <div className="form-actions">
                <button type="submit" className="btn btn-success">
                  Сохранить
                </button>
                <button type="button" className="btn btn-secondary" onClick={() => setEditMode(false)}>
                  Отмена
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="project-info card mb-3">
          <div className="card-body">
            <div className="project-details">
              <div className="detail-item">
                <span className="detail-label">Описание:</span>
                <span className="detail-value">{project.description || 'Нет описания'}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Статус:</span>
                <span className={`badge ${project.status === 'active' ? 'badge-success' : project.status === 'paused' ? 'badge-warning' : 'badge-secondary'}`}>
                  {project.status === 'active' ? 'Активный' : project.status === 'paused' ? 'Приостановлен' : 'Архивирован'}
                </span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Дата создания:</span>
                <span className="detail-value">{new Date(project.created_at).toLocaleDateString('ru-RU')}</span>
              </div>
            </div>
            <div className="project-summary">
              <div className="summary-item">
                <span className="summary-value">{links.length}</span>
                <span className="summary-label">Ссылок</span>
              </div>
              <div className="summary-item">
                <span className="summary-value">{project.clicks_count || 0}</span>
                <span className="summary-label">Кликов</span>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="section">
        <div className="section-header">
          <h2>Ссылки</h2>
          <Link to={`/projects/${id}/links/create`} className="btn btn-primary">
            Создать ссылку
          </Link>
        </div>

        {links.length === 0 ? (
          <div className="alert alert-info">
            В этом проекте пока нет ссылок. Создайте свою первую ссылку!
          </div>
        ) : (
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Slug</th>
                  <th>Назначение</th>
                  <th>Клики</th>
                  <th>Статус</th>
                  <th>Действия</th>
                </tr>
              </thead>
              <tbody>
                {links.map(link => (
                  <tr key={link.id}>
                    <td>{link.name}</td>
                    <td>
                      <a
                        href={`${window.location.origin}/r/${link.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        /r/{link.slug}
                      </a>
                    </td>
                    <td className="truncate">{link.destination_url}</td>
                    <td>{link.clicks_count || 0}</td>
                    <td>
                      <span className={`badge ${link.is_active ? 'badge-success' : 'badge-secondary'}`}>
                        {link.is_active ? 'Активна' : 'Неактивна'}
                      </span>
                    </td>
                    <td>
                      <div className="btn-group">
                        <Link to={`/links/${link.id}`} className="btn btn-sm btn-primary">
                          Детали
                        </Link>
                        <Link to={`/links/${link.id}/edit`} className="btn btn-sm btn-secondary">
                          Изменить
                        </Link>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDeleteLink(link.id)}
                        >
                          Удалить
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {stats && links.length > 0 && (
        <div className="section">
          <h2>Статистика проекта</h2>
          
          <div className="charts-container">
            <div className="chart-card">
              <h3>Клики по устройствам</h3>
              <div className="chart">
                <Pie data={deviceChartData} options={chartOptions} />
              </div>
            </div>
            <div className="chart-card">
              <h3>Топ ссылок по кликам</h3>
              <div className="chart">
                <Bar data={linkChartData} options={chartOptions} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDetail;