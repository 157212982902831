// client/src/pages/projects/ProjectsList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import './Projects.css';

const ProjectsList = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newProject, setNewProject] = useState({ name: '', description: '' });

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await api.get('/api/projects');
      setProjects(response.data);
    } catch (error) {
      setError('Ошибка загрузки проектов');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateProject = async (e) => {
    e.preventDefault();
    
    try {
      const response = await api.post('/api/projects', newProject);
      setProjects([...projects, response.data]);
      setNewProject({ name: '', description: '' });
      setShowCreateForm(false);
      toast.success('Проект успешно создан!');
    } catch (error) {
      toast.error('Ошибка создания проекта');
      console.error(error);
    }
  };

  const handleDeleteProject = async (id) => {
    if (!window.confirm('Вы уверены, что хотите удалить этот проект?')) {
      return;
    }
    
    try {
      await api.delete(`/api/projects/${id}`);
      setProjects(projects.filter(project => project.id !== id));
      toast.success('Проект успешно удален!');
    } catch (error) {
      toast.error('Ошибка удаления проекта');
      console.error(error);
    }
  };

  if (loading) {
    return (
      <div className="loading">
        <div className="spinner"></div>
        <p>Загрузка проектов...</p>
      </div>
    );
  }

  return (
    <div className="projects-list">
      <div className="page-header">
        <h1>Проекты</h1>
        <button 
          className="btn btn-primary" 
          onClick={() => setShowCreateForm(!showCreateForm)}
        >
          {showCreateForm ? 'Отмена' : 'Создать проект'}
        </button>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}

      {showCreateForm && (
        <div className="card mb-3">
          <div className="card-header">
            <h2>Новый проект</h2>
          </div>
          <div className="card-body">
            <form onSubmit={handleCreateProject}>
              <div className="form-group">
                <label htmlFor="name">Название проекта</label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  value={newProject.name}
                  onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Описание (необязательно)</label>
                <textarea
                  id="description"
                  className="form-control"
                  rows="3"
                  value={newProject.description}
                  onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
                />
              </div>
              <button type="submit" className="btn btn-success">
                Создать проект
              </button>
            </form>
          </div>
        </div>
      )}

      {projects.length === 0 ? (
        <div className="alert alert-info">
          У вас еще нет проектов. Создайте свой первый проект!
        </div>
      ) : (
        <div className="projects-grid">
          {projects.map(project => (
            <div key={project.id} className="project-card">
              <div className="project-card-header">
                <h2>{project.name}</h2>
                <div className="project-actions">
                  <button 
                    className="btn btn-sm btn-danger"
                    onClick={() => handleDeleteProject(project.id)}
                  >
                    Удалить
                  </button>
                </div>
              </div>
              <div className="project-card-body">
                {project.description && <p>{project.description}</p>}
                <div className="project-stats">
                  <div className="stat">
                    <span className="stat-value">{project.links_count}</span>
                    <span className="stat-label">Ссылок</span>
                  </div>
                  <div className="stat">
                    <span className="stat-value">{project.clicks_count}</span>
                    <span className="stat-label">Кликов</span>
                  </div>
                </div>
              </div>
              <div className="project-card-footer">
                <Link to={`/projects/${project.id}`} className="btn btn-primary">
                  Просмотр
                </Link>
                <span className="project-date">
                  Создан: {new Date(project.created_at).toLocaleDateString('ru-RU')}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectsList;