// client/src/pages/Profile.js
import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';
import { toast } from 'react-toastify';
import './Profile.css';

const Profile = () => {
  const { currentUser, logout } = useAuth();
  const [editMode, setEditMode] = useState(false);
  const [userData, setUserData] = useState({
    username: currentUser?.username || '',
    email: currentUser?.email || '',
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (userData.password && userData.password !== userData.confirmPassword) {
      return toast.error('Пароли не совпадают');
    }
    
    setLoading(true);
    
    try {
      // Мы не имеем эндпоинта обновления пользователя в нашем API,
      // поэтому здесь будет эмуляция запроса
      // В реальном приложении здесь должен быть запрос к API
      
      await new Promise(resolve => setTimeout(resolve, 1000)); // Эмуляция задержки запроса
      
      toast.success('Профиль успешно обновлен!');
      setEditMode(false);
    } catch (error) {
      toast.error('Ошибка обновления профиля');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="profile">
      <div className="page-header">
        <h1>Мой профиль</h1>
        {!editMode && (
          <button className="btn btn-primary" onClick={() => setEditMode(true)}>
            Редактировать
          </button>
        )}
      </div>

      <div className="card">
        <div className="card-body">
          {editMode ? (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="username">Имя пользователя</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  className="form-control"
                  value={userData.username}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  value={userData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Новый пароль (оставьте пустым, чтобы не менять)</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  value={userData.password}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Подтверждение пароля</label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  className="form-control"
                  value={userData.confirmPassword}
                  onChange={handleInputChange}
                  disabled={!userData.password}
                />
              </div>
              <div className="form-actions">
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={loading}
                >
                  {loading ? 'Сохранение...' : 'Сохранить изменения'}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setEditMode(false)}
                  disabled={loading}
                >
                  Отмена
                </button>
              </div>
            </form>
          ) : (
            <div className="profile-info">
              <div className="profile-item">
                <span className="profile-label">Имя пользователя:</span>
                <span className="profile-value">{currentUser?.username}</span>
              </div>
              <div className="profile-item">
                <span className="profile-label">Email:</span>
                <span className="profile-value">{currentUser?.email}</span>
              </div>
              <div className="profile-item">
                <span className="profile-label">Роль:</span>
                <span className="profile-value">
                  {currentUser?.is_admin ? 'Администратор' : 'Пользователь'}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="card mt-4">
        <div className="card-header">
          <h2>Действия</h2>
        </div>
        <div className="card-body">
          <button className="btn btn-danger" onClick={logout}>
            Выйти из аккаунта
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;