// client/src/components/Sidebar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './Sidebar.css';

const Sidebar = () => {
  const { currentUser } = useAuth();

  return (
    <aside className="sidebar">
      <div className="sidebar-content">
        <div className="sidebar-header">
          <h3>Меню</h3>
        </div>
        <ul className="sidebar-menu">
          <li>
            <NavLink to="/" end>Панель управления</NavLink>
          </li>
          <li>
            <NavLink to="/projects">Проекты</NavLink>
          </li>
          <li>
            <NavLink to="/reports">Отчеты</NavLink>
          </li>
          {currentUser?.is_admin && (
            <>
              <li className="sidebar-divider">Администрирование</li>
              <li>
                <NavLink to="/admin/users">Пользователи</NavLink>
              </li>
              <li>
                <NavLink to="/admin/stats">Статистика системы</NavLink>
              </li>
            </>
          )}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;