// client/src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

// Layouts
import MainLayout from './layouts/MainLayout';
import AuthLayout from './layouts/AuthLayout';

// Pages
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import ProjectsList from './pages/projects/ProjectsList';
import ProjectDetail from './pages/projects/ProjectDetail';
import LinkCreate from './pages/links/LinkCreate';
import LinkEdit from './pages/links/LinkEdit';
import LinkDetail from './pages/links/LinkDetail';
import ReportsList from './pages/reports/ReportsList';
import ReportDetail from './pages/reports/ReportDetail';
import ReportCreate from './pages/reports/ReportCreate';
import Profile from './pages/Profile';
import AdminUsers from './pages/admin/AdminUsers';
import AdminStats from './pages/admin/AdminStats';
import NotFound from './pages/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';

function App() {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Загрузка...</p>
      </div>
    );
  }

  return (
    <Routes>
      {/* Маршруты авторизации */}
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Route>

      {/* Защищенные маршруты */}
      <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/projects" element={<ProjectsList />} />
          <Route path="/projects/:id" element={<ProjectDetail />} />
          <Route path="/projects/:projectId/links/create" element={<LinkCreate />} />
          <Route path="/links/:id" element={<LinkDetail />} />
          <Route path="/links/:id/edit" element={<LinkEdit />} />
          <Route path="/reports" element={<ReportsList />} />
          <Route path="/reports/create" element={<ReportCreate />} />
          <Route path="/reports/:id" element={<ReportDetail />} />
          <Route path="/profile" element={<Profile />} />
          
          {/* Административные маршруты */}
          <Route element={<AdminRoute />}>
            <Route path="/admin/users" element={<AdminUsers />} />
            <Route path="/admin/stats" element={<AdminStats />} />
          </Route>
        </Route>
      </Route>

      {/* Маршрут 404 */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;