// client/src/pages/reports/ReportsList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import './Reports.css';

const ReportsList = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async () => {
    try {
      const response = await api.get('/api/reports');
      setReports(response.data);
    } catch (error) {
      setError('Ошибка загрузки отчетов');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteReport = async (id) => {
    if (!window.confirm('Вы уверены, что хотите удалить этот отчет?')) {
      return;
    }
    
    try {
      await api.delete(`/api/reports/${id}`);
      setReports(reports.filter(report => report.id !== id));
      toast.success('Отчет успешно удален!');
    } catch (error) {
      toast.error('Ошибка удаления отчета');
      console.error(error);
    }
  };

  if (loading) {
    return (
      <div className="loading">
        <div className="spinner"></div>
        <p>Загрузка отчетов...</p>
      </div>
    );
  }

  return (
    <div className="reports-list">
      <div className="page-header">
        <h1>Отчеты</h1>
        <Link to="/reports/create" className="btn btn-primary">
          Создать отчет
        </Link>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}

      {reports.length === 0 ? (
        <div className="alert alert-info">
          У вас пока нет отчетов. Создайте свой первый отчет!
        </div>
      ) : (
        <div className="reports-grid">
          {reports.map(report => (
            <div key={report.id} className="report-card">
              <div className="report-card-header">
                <h2>{report.name}</h2>
                <div className="report-actions">
                  <button 
                    className="btn btn-sm btn-danger"
                    onClick={() => handleDeleteReport(report.id)}
                  >
                    Удалить
                  </button>
                </div>
              </div>
              <div className="report-card-body">
                <div className="report-type">
                  <span className="type-label">Тип отчета:</span>
                  <span className="type-value">
                    {report.type === 'project_summary' ? 'Сводка по проекту' : 
                     report.type === 'link_detail' ? 'Подробности по ссылке' :
                     report.type}
                  </span>
                </div>
                <div className="report-dates">
                  <div className="date-item">
                    <span className="date-label">Создан:</span>
                    <span className="date-value">
                      {new Date(report.created_at).toLocaleDateString('ru-RU')}
                    </span>
                  </div>
                  {report.last_run && (
                    <div className="date-item">
                      <span className="date-label">Последний запуск:</span>
                      <span className="date-value">
                        {new Date(report.last_run).toLocaleDateString('ru-RU')}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="report-card-footer">
                <Link to={`/reports/${report.id}`} className="btn btn-primary">
                  Просмотреть отчет
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReportsList;