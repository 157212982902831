// client/src/pages/links/LinkDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Line, Pie, Bar } from 'react-chartjs-2';
import { Chart, ArcElement, BarElement, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import './Links.css';

// Регистрация необходимых компонентов Chart.js
Chart.register(ArcElement, BarElement, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const LinkDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [link, setLink] = useState(null);
  const [clicks, setClicks] = useState([]);
  const [stats, setStats] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    total: 0,
    pages: 1
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchLinkData = async () => {
      try {
        // Получение информации о ссылке
        const linkResponse = await api.get(`/api/links/${id}`);
        setLink(linkResponse.data);
        
        // Получение статистики кликов
        const clicksResponse = await api.get(`/api/clicks/link/${id}`, {
          params: { page: pagination.page, limit: pagination.limit }
        });
        setClicks(clicksResponse.data.clicks);
        setPagination(clicksResponse.data.pagination);
        
        // Получение агрегированной статистики
        fetchStats();
      } catch (error) {
        setError('Ошибка загрузки данных ссылки');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchLinkData();
  }, [id, pagination.page, pagination.limit]);

  const fetchStats = async () => {
    try {
      // Запрашиваем статистику через API
      // Это эмуляция, т.к. у нас нет отдельного эндпоинта для статистики ссылки
      // В реальном приложении здесь должен быть запрос к API
      
      const clicksResponse = await api.get(`/api/clicks/link/${id}`, {
        params: { limit: 1000 } // Получаем больше данных для статистики
      });
      
      const clicksData = clicksResponse.data.clicks;
      
      // Группировка по датам
      const dateGroups = {};
      const countries = {};
      const devices = {};
      const browsers = {};
      
      clicksData.forEach(click => {
        // Группировка по датам
        const date = new Date(click.created_at).toLocaleDateString('ru-RU');
        dateGroups[date] = (dateGroups[date] || 0) + 1;
        
        // Группировка по странам
        countries[click.country || 'Неизвестно'] = (countries[click.country || 'Неизвестно'] || 0) + 1;
        
        // Группировка по устройствам
        devices[click.device_type || 'Неизвестно'] = (devices[click.device_type || 'Неизвестно'] || 0) + 1;
        
        // Группировка по браузерам
        browsers[click.browser || 'Неизвестно'] = (browsers[click.browser || 'Неизвестно'] || 0) + 1;
      });
      
      // Преобразование в формат для графиков
      const timeStats = Object.keys(dateGroups).map(date => ({
        date,
        clicks: dateGroups[date]
      })).sort((a, b) => new Date(a.date) - new Date(b.date));
      
      const countryStats = Object.keys(countries).map(country => ({
        country,
        clicks: countries[country]
      })).sort((a, b) => b.clicks - a.clicks);
      
      const deviceStats = Object.keys(devices).map(device => ({
        device,
        clicks: devices[device]
      })).sort((a, b) => b.clicks - a.clicks);
      
      const browserStats = Object.keys(browsers).map(browser => ({
        browser,
        clicks: browsers[browser]
      })).sort((a, b) => b.clicks - a.clicks);
      
      setStats({
        timeStats,
        countryStats,
        deviceStats,
        browserStats
      });
    } catch (error) {
      console.error('Ошибка загрузки статистики:', error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > pagination.pages) return;
    setPagination({ ...pagination, page: newPage });
  };

  const handleDeleteLink = async () => {
    if (!window.confirm('Вы уверены, что хотите удалить эту ссылку?')) {
      return;
    }
    
    try {
      await api.delete(`/api/links/${id}`);
      toast.success('Ссылка успешно удалена!');
      navigate(`/projects/${link.project_id}`);
    } catch (error) {
      toast.error('Ошибка удаления ссылки');
      console.error(error);
    }
  };

  const copyToClipboard = () => {
    const url = `${window.location.origin}/r/${link.slug}`;
    navigator.clipboard.writeText(url);
    toast.success('Ссылка скопирована в буфер обмена!');
  };

  if (loading) {
    return (
      <div className="loading">
        <div className="spinner"></div>
        <p>Загрузка данных ссылки...</p>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  // Подготовка данных для графиков
  const timeChartData = stats && {
    labels: stats.timeStats.map(item => item.date),
    datasets: [
      {
        label: 'Клики',
        data: stats.timeStats.map(item => item.clicks),
        borderColor: 'rgba(74, 108, 247, 1)',
        backgroundColor: 'rgba(74, 108, 247, 0.1)',
        tension: 0.4,
        fill: true,
      },
    ],
  };

  const deviceChartData = stats && {
    labels: stats.deviceStats.map(item => item.device),
    datasets: [
      {
        data: stats.deviceStats.map(item => item.clicks),
        backgroundColor: [
          'rgba(74, 108, 247, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const countryChartData = stats && {
    labels: stats.countryStats.slice(0, 10).map(item => item.country),
    datasets: [
      {
        label: 'Клики',
        data: stats.countryStats.slice(0, 10).map(item => item.clicks),
        backgroundColor: 'rgba(74, 108, 247, 0.8)',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="link-detail">
      <div className="page-header">
        <div className="header-content">
          <h1>{link.name}</h1>
          <div className="link-url">
            <span className="url-label">URL:</span>
            <a
              href={`${window.location.origin}/r/${link.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${window.location.origin}/r/${link.slug}`}
            </a>
            <button className="btn btn-sm btn-outline-primary" onClick={copyToClipboard}>
              Копировать
            </button>
          </div>
        </div>
        <div className="header-actions">
          <Link to={`/links/${id}/edit`} className="btn btn-primary">
            Редактировать
          </Link>
          <button className="btn btn-danger" onClick={handleDeleteLink}>
            Удалить
          </button>
        </div>
      </div>

      <div className="link-info card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-8">
              <div className="detail-item">
                <span className="detail-label">Исходный URL:</span>
                <a href={link.source_url} target="_blank" rel="noopener noreferrer" className="detail-value">
                  {link.source_url}
                </a>
              </div>
              <div className="detail-item">
                <span className="detail-label">URL назначения:</span>
                <a href={link.destination_url} target="_blank" rel="noopener noreferrer" className="detail-value">
                  {link.destination_url}
                </a>
              </div>
              <div className="detail-item">
                <span className="detail-label">Тип ссылки:</span>
                <span className="detail-value">
                  {link.type === 'redirect' ? 'Редирект' : link.type === 'iframe' ? 'Iframe' : 'JavaScript'}
                </span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Статус:</span>
                <span className={`badge ${link.is_active ? 'badge-success' : 'badge-secondary'}`}>
                  {link.is_active ? 'Активна' : 'Неактивна'}
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="link-summary">
                <div className="summary-item">
                  <span className="summary-value">{link.clicks_count || 0}</span>
                  <span className="summary-label">Кликов</span>
                </div>
                <div className="summary-item">
                  <span className="summary-value">{new Date(link.created_at).toLocaleDateString('ru-RU')}</span>
                  <span className="summary-label">Дата создания</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {stats && (
        <div className="stats-section mb-4">
          <h2>Статистика</h2>
          
          <div className="charts-container">
            <div className="chart-card">
              <h3>Клики по времени</h3>
              <div className="chart">
                {timeChartData && <Line data={timeChartData} options={chartOptions} />}
              </div>
            </div>
            
            <div className="chart-card">
              <h3>Клики по устройствам</h3>
              <div className="chart">
                {deviceChartData && <Pie data={deviceChartData} options={{ plugins: { legend: { position: 'right' } } }} />}
              </div>
            </div>
            
            <div className="chart-card">
              <h3>Топ стран</h3>
              <div className="chart">
                {countryChartData && <Bar data={countryChartData} options={chartOptions} />}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="clicks-section">
        <h2>История кликов</h2>
        
        {clicks.length === 0 ? (
          <div className="alert alert-info">
            По этой ссылке пока нет кликов
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Дата и время</th>
                    <th>IP адрес</th>
                    <th>Страна</th>
                    <th>Устройство</th>
                    <th>Браузер</th>
                    <th>ОС</th>
                    <th>Реферер</th>
                  </tr>
                </thead>
                <tbody>
                  {clicks.map(click => (
                    <tr key={click.id}>
                      <td>{new Date(click.created_at).toLocaleString('ru-RU')}</td>
                      <td>{click.ip_address}</td>
                      <td>{click.country || 'Неизвестно'}</td>
                      <td>{click.device_type || 'Неизвестно'}</td>
                      <td>{click.browser || 'Неизвестно'}</td>
                      <td>{click.os || 'Неизвестно'}</td>
                      <td className="truncate">{click.referer || '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            
            {pagination.pages > 1 && (
              <div className="pagination-container">
                <button
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => handlePageChange(1)}
                  disabled={pagination.page === 1}
                >
                  В начало
                </button>
                <button
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => handlePageChange(pagination.page - 1)}
                  disabled={pagination.page === 1}
                >
                  Предыдущая
                </button>
                <span className="pagination-info">
                  Страница {pagination.page} из {pagination.pages}
                </span>
                <button
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => handlePageChange(pagination.page + 1)}
                  disabled={pagination.page === pagination.pages}
                >
                  Следующая
                </button>
                <button
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => handlePageChange(pagination.pages)}
                  disabled={pagination.page === pagination.pages}
                >
                  В конец
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LinkDetail;