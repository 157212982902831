// client/src/pages/links/LinkEdit.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import './Links.css';

const LinkEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [link, setLink] = useState(null);
  const [newParam, setNewParam] = useState({ param_name: '', param_value: '', is_active: true });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchLink = async () => {
      try {
        const response = await api.get(`/api/links/${id}`);
        setLink({
          ...response.data,
          parameters: response.data.parameters || []
        });
      } catch (error) {
        setError('Ошибка загрузки ссылки');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchLink();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLink({
      ...link,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleParamInputChange = (e) => {
    const { name, value } = e.target;
    setNewParam({
      ...newParam,
      [name]: value
    });
  };

  const handleAddParam = () => {
    if (!newParam.param_name) {
      return toast.error('Необходимо указать имя параметра');
    }
    
    setLink({
      ...link,
      parameters: [...link.parameters, newParam]
    });
    
    setNewParam({ param_name: '', param_value: '', is_active: true });
  };

  const handleRemoveParam = (index) => {
    const updatedParams = [...link.parameters];
    updatedParams.splice(index, 1);
    
    setLink({
      ...link,
      parameters: updatedParams
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await api.put(`/api/links/${id}`, link);
      toast.success('Ссылка успешно обновлена!');
      navigate(`/links/${response.data.id}`);
    } catch (error) {
      toast.error('Ошибка обновления ссылки');
      console.error(error);
    }
  };

  if (loading) {
    return (
      <div className="loading">
        <div className="spinner"></div>
        <p>Загрузка данных ссылки...</p>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className="link-edit">
      <div className="page-header">
        <h1>Редактирование ссылки</h1>
      </div>
      
      <div className="card">
        <div className="card-header">
          <h2>{link.name}</h2>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Название ссылки</label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                value={link.name}
                onChange={handleInputChange}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="source_url">Исходный URL (откуда будет трафик)</label>
              <input
                type="url"
                id="source_url"
                name="source_url"
                className="form-control"
                value={link.source_url}
                onChange={handleInputChange}
                required
              />
              <small className="form-text">URL, с которого ожидается трафик (например, рекламная площадка)</small>
            </div>
            
            <div className="form-group">
              <label htmlFor="destination_url">URL назначения (куда будет редирект)</label>
              <input
                type="url"
                id="destination_url"
                name="destination_url"
                className="form-control"
                value={link.destination_url}
                onChange={handleInputChange}
                required
              />
              <small className="form-text">URL, на который будет перенаправлен пользователь</small>
            </div>
            
            <div className="form-group">
              <label htmlFor="slug">Слаг</label>
              <input
                type="text"
                id="slug"
                name="slug"
                className="form-control"
                value={link.slug}
                onChange={handleInputChange}
                required
              />
              <small className="form-text">Уникальный идентификатор для URL</small>
            </div>
            
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="type">Тип ссылки</label>
                <select
                  id="type"
                  name="type"
                  className="form-control"
                  value={link.type}
                  onChange={handleInputChange}
                >
                  <option value="redirect">Редирект</option>
                  <option value="iframe">Iframe</option>
                  <option value="javascript">JavaScript</option>
                </select>
              </div>
              
              <div className="form-group col-md-4">
                <label htmlFor="weight">Вес</label>
                <input
                  type="number"
                  id="weight"
                  name="weight"
                  className="form-control"
                  min="1"
                  value={link.weight}
                  onChange={handleInputChange}
                />
                <small className="form-text">Используется для распределения трафика</small>
              </div>
              
              <div className="form-group col-md-4">
                <div className="form-check mt-4">
                  <input
                    type="checkbox"
                    id="is_active"
                    name="is_active"
                    className="form-check-input"
                    checked={link.is_active}
                    onChange={handleInputChange}
                  />
                  <label className="form-check-label" htmlFor="is_active">
                    Активная ссылка
                  </label>
                </div>
              </div>
            </div>
            
            <div className="parameters-section mt-4">
              <h3>Дополнительные параметры</h3>
              <p>Добавьте параметры для отслеживания или передачи данных</p>
              
              <div className="parameters-list">
                {link.parameters.length > 0 ? (
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th>Имя параметра</th>
                        <th>Значение</th>
                        <th>Действия</th>
                      </tr>
                    </thead>
                    <tbody>
                      {link.parameters.map((param, index) => (
                        <tr key={index}>
                          <td>{param.param_name}</td>
                          <td>{param.param_value}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => handleRemoveParam(index)}
                            >
                              Удалить
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="alert alert-info">
                    Нет добавленных параметров
                  </div>
                )}
              </div>
              
              <div className="add-parameter-form mt-3">
                <div className="form-row">
                  <div className="form-group col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Имя параметра"
                      name="param_name"
                      value={newParam.param_name}
                      onChange={handleParamInputChange}
                    />
                  </div>
                  <div className="form-group col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Значение"
                      name="param_value"
                      value={newParam.param_value}
                      onChange={handleParamInputChange}
                    />
                  </div>
                  <div className="form-group col-md-2">
                    <button
                      type="button"
                      className="btn btn-success btn-block"
                      onClick={handleAddParam}
                    >
                      Добавить
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="form-actions mt-4">
              <button type="submit" className="btn btn-primary">
                Сохранить изменения
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => navigate(`/links/${id}`)}
              >
                Отмена
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LinkEdit;