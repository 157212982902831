// client/src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './Navbar.css';

const Navbar = () => {
  const { currentUser, logout } = useAuth();

  const handleLogout = async () => {
    await logout();
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="navbar-content">
          <div className="navbar-logo">
            <Link to="/">TrackLinks</Link>
          </div>
          <div className="navbar-menu">
            <div className="navbar-user">
              <span>Привет, {currentUser?.username}</span>
              <div className="navbar-dropdown">
                <Link to="/profile">Профиль</Link>
                {currentUser?.is_admin && (
                  <>
                    <Link to="/admin/users">Пользователи</Link>
                    <Link to="/admin/stats">Статистика системы</Link>
                  </>
                )}
                <button onClick={handleLogout}>Выйти</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;