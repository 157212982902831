// client/src/pages/reports/ReportCreate.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import './Reports.css';

const ReportCreate = () => {
  const navigate = useNavigate();
  const [reportType, setReportType] = useState('project_summary');
  const [name, setName] = useState('');
  const [projects, setProjects] = useState([]);
  const [links, setLinks] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedLink, setSelectedLink] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await api.get('/api/projects');
      setProjects(response.data);
      
      if (response.data.length > 0) {
        setSelectedProject(response.data[0].id);
        fetchLinks(response.data[0].id);
      }
      
      setLoading(false);
    } catch (error) {
      setError('Ошибка загрузки проектов');
      console.error(error);
      setLoading(false);
    }
  };

  const fetchLinks = async (projectId) => {
    try {
      const response = await api.get(`/api/links/project/${projectId}`);
      setLinks(response.data);
      
      if (response.data.length > 0) {
        setSelectedLink(response.data[0].id);
      } else {
        setSelectedLink('');
      }
    } catch (error) {
      console.error('Ошибка загрузки ссылок:', error);
    }
  };

  const handleProjectChange = (e) => {
    const projectId = e.target.value;
    setSelectedProject(projectId);
    fetchLinks(projectId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!name) {
      return toast.error('Укажите название отчета');
    }
    
    if (reportType === 'project_summary' && !selectedProject) {
      return toast.error('Выберите проект');
    }
    
    if (reportType === 'link_detail' && !selectedLink) {
      return toast.error('Выберите ссылку');
    }
    
    try {
      const reportData = {
        name,
        type: reportType,
        config: reportType === 'project_summary'
          ? { project_id: selectedProject }
          : { link_id: selectedLink }
      };
      
      const response = await api.post('/api/reports', reportData);
      toast.success('Отчет успешно создан!');
      navigate(`/reports/${response.data.id}`);
    } catch (error) {
      toast.error('Ошибка создания отчета');
      console.error(error);
    }
  };

  if (loading) {
    return (
      <div className="loading">
        <div className="spinner"></div>
        <p>Загрузка данных...</p>
      </div>
    );
  }

  return (
    <div className="report-create">
      <div className="page-header">
        <h1>Создание отчета</h1>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}

      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Название отчета</label>
              <input
                type="text"
                id="name"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="reportType">Тип отчета</label>
              <select
                id="reportType"
                className="form-control"
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
              >
                <option value="project_summary">Сводка по проекту</option>
                <option value="link_detail">Подробности по ссылке</option>
              </select>
            </div>

            {reportType === 'project_summary' && (
              <div className="form-group">
                <label htmlFor="project">Выберите проект</label>
                {projects.length > 0 ? (
                  <select
                    id="project"
                    className="form-control"
                    value={selectedProject}
                    onChange={handleProjectChange}
                  >
                    {projects.map(project => (
                      <option key={project.id} value={project.id}>
                        {project.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <div className="alert alert-warning">
                    У вас нет проектов. Сначала создайте проект.
                  </div>
                )}
              </div>
            )}

            {reportType === 'link_detail' && (
              <>
                <div className="form-group">
                  <label htmlFor="project">Выберите проект</label>
                  {projects.length > 0 ? (
                    <select
                      id="project"
                      className="form-control"
                      value={selectedProject}
                      onChange={handleProjectChange}
                    >
                      {projects.map(project => (
                        <option key={project.id} value={project.id}>
                          {project.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="alert alert-warning">
                      У вас нет проектов. Сначала создайте проект.
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="link">Выберите ссылку</label>
                  {links.length > 0 ? (
                    <select
                      id="link"
                      className="form-control"
                      value={selectedLink}
                      onChange={(e) => setSelectedLink(e.target.value)}
                    >
                      {links.map(link => (
                        <option key={link.id} value={link.id}>
                          {link.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="alert alert-warning">
                      В выбранном проекте нет ссылок. Сначала создайте ссылку.
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="form-actions">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  !name ||
                  (reportType === 'project_summary' && !selectedProject) ||
                  (reportType === 'link_detail' && !selectedLink)
                }
              >
                Создать отчет
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => navigate('/reports')}
              >
                Отмена
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReportCreate;