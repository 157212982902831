// client/src/pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import './Dashboard.css';

// Регистрация необходимых компонентов Chart.js
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await api.get('/api/dashboard');
        setDashboardData(response.data);
      } catch (error) {
        setError('Ошибка загрузки данных панели управления');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return (
      <div className="loading">
        <div className="spinner"></div>
        <p>Загрузка данных...</p>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  // Подготовка данных для графика
  const chartData = {
    labels: dashboardData.recentClicks.map(item => new Date(item.day).toLocaleDateString('ru-RU')),
    datasets: [
      {
        label: 'Клики',
        data: dashboardData.recentClicks.map(item => item.click_count),
        borderColor: 'rgba(74, 108, 247, 1)',
        backgroundColor: 'rgba(74, 108, 247, 0.1)',
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Клики за последние 30 дней',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="dashboard">
      <h1>Панель управления</h1>

      <div className="stats-cards">
        <div className="stat-card">
          <div className="stat-card-value">{dashboardData.summary.projectsCount}</div>
          <div className="stat-card-label">Проекты</div>
        </div>
        <div className="stat-card">
          <div className="stat-card-value">{dashboardData.summary.linksCount}</div>
          <div className="stat-card-label">Ссылки</div>
        </div>
        <div className="stat-card">
          <div className="stat-card-value">{dashboardData.summary.clicksCount}</div>
          <div className="stat-card-label">Клики</div>
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          <div className="card">
            <div className="card-header">
              <h2>Статистика кликов</h2>
            </div>
            <div className="card-body">
              <div className="chart-container">
                <Line data={chartData} options={chartOptions} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-header">
              <h2>Топ ссылок</h2>
            </div>
            <div className="card-body">
              <ul className="top-links">
                {dashboardData.topLinks.map(link => (
                  <li key={link.id}>
                    <Link to={`/links/${link.id}`}>
                      <span className="link-name">{link.name}</span>
                      <span className="link-clicks">{link.click_count} кликов</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h2>Последние проекты</h2>
          <Link to="/projects" className="btn btn-outline-primary btn-sm">
            Все проекты
          </Link>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Ссылки</th>
                  <th>Клики</th>
                  <th>Дата создания</th>
                  <th>Действия</th>
                </tr>
              </thead>
              <tbody>
                {dashboardData.recentProjects.map(project => (
                  <tr key={project.id}>
                    <td>{project.name}</td>
                    <td>{project.links_count}</td>
                    <td>{project.clicks_count}</td>
                    <td>{new Date(project.created_at).toLocaleDateString('ru-RU')}</td>
                    <td>
                      <Link to={`/projects/${project.id}`} className="btn btn-sm btn-primary">
                        Подробнее
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;