// client/src/pages/reports/ReportDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Chart, ArcElement, BarElement, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import './Reports.css';

// Регистрация необходимых компонентов Chart.js
Chart.register(ArcElement, BarElement, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const ReportDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [report, setReport] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchReport();
  }, [id]);

  const fetchReport = async () => {
    try {
      const response = await api.get(`/api/reports/${id}`);
      setReport(response.data.report);
      setReportData(response.data.data);
    } catch (error) {
      setError('Ошибка загрузки отчета');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteReport = async () => {
    if (!window.confirm('Вы уверены, что хотите удалить этот отчет?')) {
      return;
    }
    
    try {
      await api.delete(`/api/reports/${id}`);
      toast.success('Отчет успешно удален!');
      navigate('/reports');
    } catch (error) {
      toast.error('Ошибка удаления отчета');
      console.error(error);
    }
  };

  if (loading) {
    return (
      <div className="loading">
        <div className="spinner"></div>
        <p>Загрузка отчета...</p>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  // Подготовка данных для графиков в зависимости от типа отчета
  let timeChartData, deviceChartData, countryChartData, linkChartData;
  
  if (report.type === 'project_summary') {
    timeChartData = {
      labels: reportData.timeStats.map(item => new Date(item.day).toLocaleDateString('ru-RU')),
      datasets: [
        {
          label: 'Клики',
          data: reportData.timeStats.map(item => item.click_count),
          borderColor: 'rgba(74, 108, 247, 1)',
          backgroundColor: 'rgba(74, 108, 247, 0.1)',
          tension: 0.4,
          fill: true,
        },
      ],
    };
    
    linkChartData = {
      labels: reportData.linkStats.slice(0, 10).map(item => item.name),
      datasets: [
        {
          label: 'Клики',
          data: reportData.linkStats.slice(0, 10).map(item => item.click_count),
          backgroundColor: 'rgba(74, 108, 247, 0.8)',
        },
      ],
    };
  } else if (report.type === 'link_detail') {
    timeChartData = {
      labels: reportData.timeStats.map(item => new Date(item.day).toLocaleDateString('ru-RU')),
      datasets: [
        {
          label: 'Клики',
          data: reportData.timeStats.map(item => item.click_count),
          borderColor: 'rgba(74, 108, 247, 1)',
          backgroundColor: 'rgba(74, 108, 247, 0.1)',
          tension: 0.4,
          fill: true,
        },
      ],
    };
    
    deviceChartData = {
      labels: reportData.deviceStats.map(item => item.device_type || 'Неизвестно'),
      datasets: [
        {
          data: reportData.deviceStats.map(item => item.click_count),
          backgroundColor: [
            'rgba(74, 108, 247, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(255, 206, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
          ],
        },
      ],
    };
    
    countryChartData = {
      labels: reportData.countryStats.slice(0, 10).map(item => item.country || 'Неизвестно'),
      datasets: [
        {
          label: 'Клики',
          data: reportData.countryStats.slice(0, 10).map(item => item.click_count),
          backgroundColor: 'rgba(74, 108, 247, 0.8)',
        },
      ],
    };
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="report-detail">
      <div className="page-header">
        <h1>{report.name}</h1>
        <div className="header-actions">
          <button className="btn btn-danger" onClick={handleDeleteReport}>
            Удалить отчет
          </button>
        </div>
      </div>

      <div className="report-info card mb-4">
        <div className="card-body">
          <div className="detail-item">
            <span className="detail-label">Тип отчета:</span>
            <span className="detail-value">
              {report.type === 'project_summary' ? 'Сводка по проекту' : 
               report.type === 'link_detail' ? 'Подробности по ссылке' :
               report.type}
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Дата создания:</span>
            <span className="detail-value">
              {new Date(report.created_at).toLocaleDateString('ru-RU')}
            </span>
          </div>
          {report.last_run && (
            <div className="detail-item">
              <span className="detail-label">Последний запуск:</span>
              <span className="detail-value">
                {new Date(report.last_run).toLocaleDateString('ru-RU')}
              </span>
            </div>
          )}
        </div>
      </div>

      {report.type === 'project_summary' && (
        <div className="report-content">
          <div className="report-section">
            <h2>Информация о проекте</h2>
            <div className="card mb-4">
              <div className="card-body">
                <div className="detail-item">
                  <span className="detail-label">Название проекта:</span>
                  <span className="detail-value">
                    {reportData.projectInfo.name}
                  </span>
                </div>
                <div className="detail-item">
                  <span className="detail-label">Описание:</span>
                  <span className="detail-value">
                    {reportData.projectInfo.description || 'Нет описания'}
                  </span>
                </div>
                <div className="detail-item">
                  <span className="detail-label">Статус:</span>
                  <span className={`badge ${reportData.projectInfo.status === 'active' ? 'badge-success' : reportData.projectInfo.status === 'paused' ? 'badge-warning' : 'badge-secondary'}`}>
                    {reportData.projectInfo.status === 'active' ? 'Активный' : reportData.projectInfo.status === 'paused' ? 'Приостановлен' : 'Архивирован'}
                  </span>
                </div>
                <div className="summary-stats">
                  <div className="stat-item">
                    <span className="stat-value">{reportData.summary.links_count}</span>
                    <span className="stat-label">Ссылок</span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-value">{reportData.summary.clicks_count}</span>
                    <span className="stat-label">Кликов</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="report-section">
            <h2>Статистика проекта</h2>
            <div className="charts-container">
              <div className="chart-card">
                <h3>Клики по времени</h3>
                <div className="chart">
                  <Line data={timeChartData} options={chartOptions} />
                </div>
              </div>
              <div className="chart-card">
                <h3>Клики по ссылкам</h3>
                <div className="chart">
                  <Bar data={linkChartData} options={chartOptions} />
                </div>
              </div>
            </div>
          </div>

          <div className="report-section">
            <h2>Ссылки проекта</h2>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Название</th>
                    <th>Слаг</th>
                    <th>Клики</th>
                    <th>Статус</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData.linkStats.map(link => (
                    <tr key={link.id}>
                      <td>{link.name}</td>
                      <td>/r/{link.slug}</td>
                      <td>{link.click_count}</td>
                      <td>
                        <Link to={`/links/${link.id}`} className="btn btn-sm btn-primary">
                          Перейти к ссылке
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {report.type === 'link_detail' && (
        <div className="report-content">
          <div className="report-section">
            <h2>Информация о ссылке</h2>
            <div className="card mb-4">
              <div className="card-body">
                <div className="detail-item">
                  <span className="detail-label">Название ссылки:</span>
                  <span className="detail-value">
                    {reportData.linkInfo.name}
                  </span>
                </div>
                <div className="detail-item">
                  <span className="detail-label">Проект:</span>
                  <span className="detail-value">
                    {reportData.linkInfo.project_name}
                  </span>
                </div>
                <div className="detail-item">
                  <span className="detail-label">URL:</span>
                  <a href={`${window.location.origin}/r/${reportData.linkInfo.slug}`} target="_blank" rel="noopener noreferrer" className="detail-value">
                    {`${window.location.origin}/r/${reportData.linkInfo.slug}`}
                  </a>
                </div>
                <div className="detail-item">
                  <span className="detail-label">URL назначения:</span>
                  <a href={reportData.linkInfo.destination_url} target="_blank" rel="noopener noreferrer" className="detail-value">
                    {reportData.linkInfo.destination_url}
                  </a>
                </div>
                <div className="detail-item">
                  <span className="detail-label">Статус:</span>
                  <span className={`badge ${reportData.linkInfo.is_active ? 'badge-success' : 'badge-secondary'}`}>
                    {reportData.linkInfo.is_active ? 'Активна' : 'Неактивна'}
                  </span>
                </div>
                <div className="summary-stats">
                  <div className="stat-item">
                    <span className="stat-value">{reportData.clicksCount}</span>
                    <span className="stat-label">Всего кликов</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="report-section">
            <h2>Статистика ссылки</h2>
            <div className="charts-container">
              <div className="chart-card">
                <h3>Клики по времени</h3>
                <div className="chart">
                  <Line data={timeChartData} options={chartOptions} />
                </div>
              </div>
              <div className="chart-card">
                <h3>Клики по устройствам</h3>
                <div className="chart">
                  <Pie data={deviceChartData} options={{ plugins: { legend: { position: 'right' } } }} />
                </div>
              </div>
              <div className="chart-card">
                <h3>Клики по странам</h3>
                <div className="chart">
                  <Bar data={countryChartData} options={chartOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportDetail;
