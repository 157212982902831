// client/src/pages/admin/AdminStats.js
import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { Line, Bar } from 'react-chartjs-2';
import { Chart, BarElement, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import './Admin.css';

// Регистрация необходимых компонентов Chart.js
Chart.register(BarElement, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const AdminStats = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      const response = await api.get('/api/admin/stats');
      setStats(response.data);
    } catch (error) {
      setError('Ошибка загрузки статистики');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="loading">
        <div className="spinner"></div>
        <p>Загрузка статистики...</p>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  // Подготовка данных для графиков
  const clicksChartData = {
    labels: stats.recentClicks.map(item => new Date(item.day).toLocaleDateString('ru-RU')),
    datasets: [
      {
        label: 'Клики',
        data: stats.recentClicks.map(item => item.click_count),
        borderColor: 'rgba(74, 108, 247, 1)',
        backgroundColor: 'rgba(74, 108, 247, 0.1)',
        tension: 0.4,
        fill: true,
      },
    ],
  };

  const topLinksChartData = {
    labels: stats.topLinks.slice(0, 10).map(item => item.name),
    datasets: [
      {
        label: 'Клики',
        data: stats.topLinks.slice(0, 10).map(item => item.click_count),
        backgroundColor: 'rgba(74, 108, 247, 0.8)',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="admin-stats">
      <div className="page-header">
        <h1>Статистика системы</h1>
      </div>

      <div className="stats-cards">
        <div className="stat-card">
          <div className="stat-icon">👤</div>
          <div className="stat-content">
            <div className="stat-value">{stats.usersCount}</div>
            <div className="stat-label">Пользователей</div>
          </div>
        </div>
        <div className="stat-card">
          <div className="stat-icon">📁</div>
          <div className="stat-content">
            <div className="stat-value">{stats.projectsCount}</div>
            <div className="stat-label">Проектов</div>
          </div>
        </div>
        <div className="stat-card">
          <div className="stat-icon">🔗</div>
          <div className="stat-content">
            <div className="stat-value">{stats.linksCount}</div>
            <div className="stat-label">Ссылок</div>
          </div>
        </div>
        <div className="stat-card">
          <div className="stat-icon">👆</div>
          <div className="stat-content">
            <div className="stat-value">{stats.clicksCount}</div>
            <div className="stat-label">Кликов</div>
          </div>
        </div>
      </div>

      <div className="charts-container">
        <div className="chart-card">
          <h2>Клики за последние 30 дней</h2>
          <div className="chart">
            <Line data={clicksChartData} options={chartOptions} />
          </div>
        </div>
        <div className="chart-card">
          <h2>Топ-10 ссылок по кликам</h2>
          <div className="chart">
            <Bar data={topLinksChartData} options={chartOptions} />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h2>Топ-10 ссылок</h2>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Слаг</th>
                  <th>Проект</th>
                  <th>Владелец</th>
                  <th>Клики</th>
                </tr>
              </thead>
              <tbody>
                {stats.topLinks.map(link => (
                  <tr key={link.id}>
                    <td>{link.name}</td>
                    <td>/r/{link.slug}</td>
                    <td>{link.project_name}</td>
                    <td>{link.owner}</td>
                    <td>{link.click_count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminStats;